import axios from 'axios';
import router from '@/router/router'
// import QS from 'qs';
import {
  Dialog,
  Toast
} from 'vant';
import wxLogin from "@/until/wxLogin";
import Device from "@/until/device/device";

//配置携带的headers参数
function headers(needToken) {
  let deviceType = ""
  let OS = ""
  let OSVersion = ""
  let netWork = ""
  let platform = ""

  if (Device){
    let device = Device.Device.MethodLibrary()
    deviceType = device.getDeviceType()
    OS = device.getOS()
    OSVersion = device.getOSVersion()
    netWork = device.getNetwork()
    platform = device.getPlatform()
  }

  let header = { //localStorage.sessionKey
      "deviceType": deviceType,
      "OS": OS,
      "OSVersion": OSVersion,
      "netWork": netWork,
      "platform": platform
  }
  if (needToken){
    header["third-session"] = localStorage.getItem("sessionKey")
  }
  return header;
}

// 实例化
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 公共地址
  timeout: 30000 // 请求超时时间
});

// 请求拦截器（因为业务需求每次token不是必须的 所以不必要拦截）
instance.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // if (localStorage.getItem("sessionKey") && !isToken) {
  // }
  config.headers = headers(localStorage.getItem("sessionKey") && !isToken);

  return config;
})

// 响应拦截器
instance.interceptors.response.use(
  response => {
    const code = response.data.code
    if (code === 60002 || code === 60003 || code === 10006) {
      Toast({
        message: response.data.msg ? response.data.msg : '登录异常，请重新登录',
        position: 'bottom'
      });
      sessionStorage.backLink = router.history.current.fullPath
      if (code === 60003){
        localStorage.removeItem('sessionKey')
        localStorage.removeItem('userInfo')
      }
      router.push('/login')
    }else if (code === 60004){
      Toast({
        message: '没有该圈子权限，请加入后再查看',
        position: 'bottom'
      });
      router.push('/')
    }else if (code === 60005){
      // 该情况需要提醒后，继续后续操作
      Toast({
        message: '没有该圈子权限，请加入后再答题',
        position: 'bottom'
      });
      return Promise.resolve(response);
    }else if (code === 60001) {
      const userInfo = JSON.parse(localStorage.userInfo)
      if (localStorage.isRefreshToken != 1 && userInfo.id != '') {
        RefreshToken()
      }
    }else if(code !== 200) {
      if (response.config.url == "/weixin/api/comment/addBehaviorRecord"){
        // 不需要提示的接口
        // console.log("")
      }else {
        Toast({
          message: response.data.msg ? response.data.msg : '数据异常',
          position: 'bottom'
        });
      }
    }else {
      return Promise.resolve(response);
    }
  },
  // 服务器状态码不是200的情况
  error => {
    if (error.response.config.url == "/weixin/api/comment/addBehaviorRecord"){
      // 不需要提示的接口
      console.log(error)
    }else {
      Toast({
        message: '服务器开小差了',
        position: 'bottom'
      });
    }
    return Promise.reject(error.response);
  }

);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    instance.get(url, {
        params: params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err ? err.data : "")
      })
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]    NBVCFXD˛报名,
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params = {}, headers={}) {
  return new Promise((resolve, reject) => {
    instance.post(url, params, headers)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err ? err.data : "")
      })
  });
}


/**
 * delete方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function pdelete(url, params = {}, header = {
  'Access-Control-Allow-Methods': 'DELETE'
}) {
  return new Promise((resolve, reject) => {
    instance.delete(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}

/**
 * 图片上传方法
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function upload(url, params = {}, header = {
  "Content-Type": "multipart/form-data"
}) {
  return new Promise((resolve, reject) => {
    instance.post(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}



function RefreshToken() {
  const userInfo = JSON.parse(localStorage.userInfo)
  get('/weixin/api/ma/wxuser/refreshToken', {
    userId: userInfo.id
  }).then(res => {
    if (res.code == 200) {
      localStorage.removeItem('isRefreshToken');
      localStorage.setItem('userInfo', JSON.stringify(res.data));
      localStorage.setItem('sessionKey', res.data.sessionKey);
      localStorage.removeItem('isRefreshToken');
      location.reload()
    } else {
      localStorage.setItem('isRefreshToken', 1);
    }
  })
}
