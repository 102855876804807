import {
  post,
  get
} from '@/until/http'

//  首页

class home {
  constructor() {}

  url = {
    bannerList: '/banner/list',
    homepPageList: 'weixin/api/ma/wxuser/homepPageList',
    communityList: '/weixin/api/ma/wxuser/myUserCommunityList',
    finishedList: '/weixin/api/ma/wxuser/finishedList',
    unfinishedList: '/weixin/api/ma/wxuser/unfinishedList',
    commentList: '/weixin/api/comment/list',
    commentAdd: '/weixin/api/comment/add',
    like: '/weixin/api/comment/like',
    reply: '/weixin/api/comment/reply',
    activity: '/weixin/api/comment/',
    activityList:'/weixin/api/ma/wxuser/activityList'
  }


  // 轮播图列表
  bannerList(para, callback) {
    get(this.url.bannerList, para).then(res => {
      callback(res);
    })
  }

  // 首页
  homepPageList(para, callback) {
    get(this.url.homepPageList, para).then(res => {
      callback(res);
    })
  }

  // 圈子列表
  communityList(para, callback) {
    get(this.url.communityList, para).then(res => {
      callback(res);
    })
  }

  // 活动详情
  activity(para, callback) {
    get(this.url.activity + para.id, {}).then(res => {
      callback(res);
    }).catch(error => {
      callback(error)
    })
  }
  
  // 已完活动
  finishedList(para, callback) {
    get(this.url.finishedList, para).then(res => {
      callback(res);
    })
  }
  
  //待办活动
  unfinishedList(para, callback) {
    get(this.url.unfinishedList, para).then(res => {
      callback(res);
    })
  }
  
  //评论列表
  commentList(para, callback) {
    get(this.url.commentList, para).then(res => {
      callback(res);
    })
  }
  
  //评论
  commentAdd(para, callback) {
    post(this.url.commentAdd, para).then(res => {
      callback(res);
    })
  }
  
  //点赞
  like(para, callback) {
    get(this.url.like, para).then(res => {
      callback(res);
    })
  }
  
  //回复
  reply(para, callback) {
    post(this.url.reply, para).then(res => {
      callback(res);
    })
  }
  
  //问卷列表
  activityList(para, callback) {
    get(this.url.activityList, para).then(res => {
      callback(res);
    })
  }

}

export default new home();
