import {
  post,
  get
} from '@/until/http'

//  我的

class my {
  constructor() {}

  url = {
    incomeList: '/weixin/api/ma/wxuser/accountTransactionList',
    eventSubmit: '/weixin/api/comment/addBehaviorRecord',
  }

  // 收支明细列表
  incomeList(para, callback) {
    get(this.url.incomeList, para).then(res => {
      callback(res);
    })
  }

  /**
   * 记录行为
   *
   * @param data
   *  {
   *   "event": "事件：survey_guide, survey_start, survey_end",
   *   "status": "仅survey有用, -1: 已结束，-2: 未开始，-3: 已参与，1: 已通过，2: 不通过，3：配额满",
   *   "activityId": "活动Id",
   *   "createTime": "创建时间",
   *   "userId": "非必填，可重复",
   *   "nickname": "Aegis"
   * }
   *
   * @param callback
   */
  submitEvent(data, callback){
    post(this.url.eventSubmit, data).then(res => {
      callback(true, res);
    }).catch(err => {
      callback(false, err)
    })
  }

}

export default new my();
