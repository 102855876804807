import home from "./home.js";
import user from "./user.js";
import uploadFile from "./uploadFile.js";
import my from './my.js'
import message from "@/api/message";

const $api = {
  home,
  uploadFile,
  user,
  my,
  message
};

const install = (Vue) => {
  Vue.prototype.$api = $api;
};

export default {
  install,
};
