<template>
  <div id="app" class="app">
    <!--    <transition name="fade" mode="in-out">-->
       <router-view></router-view>
    <!--    </transition>-->
  </div>
</template>

<script>
  export default {
    name: 'App',
    components: {},
    created() {

    },
    watch: {
      $route(to) {
        console.log(document.body.clientWidth, to)
        // 在iframe内
        let inIframe = window.frames.length != parent.frames.length;
        if (document.body.clientWidth>1000 && !inIframe && to.path != '/pc') {
          let s = to.fullPath.replaceAll("=", "%3D").replaceAll("&", "%26");
          window.location.href = "/pc?path=" + s
        }
      }
    },
  }
</script>

<style lang="less" scoped>
  html,
  body,
  #app {
    height: 100%;
    -webkit-overflow-scrolling: touch
  }
 

  .fade-enter {
    opacity: 0;
  }

  .fade-leave {
    opacity: 1;
  }

  .fade-enter-active {

    transition: opacity .5s;

  }

  .fade-leave-active {
    opacity: 0;
    transition: opacity .1s;
  }

  .fade-leave-to {
    display: none;
  }

 
</style>
