import {
  post,
  get
} from '@/until/http'

class Message {
  constructor() {
  }

  /**
   * 获取消息列表
   *
   * @param params {{pageSize: number, pageNum: number}}
   * @param callback
   */
  getMessageList(params, callback){
    get("/weixin/api/ma/wxuser/messageRecordList", params).then(
      res => {
        callback(res)
      }
    ).catch(error => {
      callback(error)
    })
  }

  /**
   * 设置消息已读
   *
   * @param params id
   * @param callback
   */
  setMessageRead(params, callback){
    get("/weixin/api/ma/wxuser/updateMessageRecord", params).then(
      res => {
        callback(res)
      }
    ).catch(error => {
      callback(error)
    })
  }
}

export default new Message()