import Vue from "vue";
import VueRouter from 'vue-router';
// 首页
import HomeMain from "@/views/home/index";
import ReleaseForm from "@/views/activity/releseForm";
import ReleaseComment from "@/views/release/index";
import SurveyGuide from "@/views/survey/guide"
Vue.use(VueRouter);


// 一组路由
export const routes = [{
    // 重定向：默认时
    path: '/',
    redirect: '/home/index'
  }, {
    path: '/home/index',
    component: HomeMain,
    name: 'home',
    children: [{
      path: '/home/index',
      component: resolve => require(['@/views/home/homeView'], resolve),
      name: 'homeIndex',
      meta: {
        title: '首页'
      }
    }, {
      path: '/home/circle',
      component: resolve => require(['@/views/home/circle'], resolve),
      name: 'homeCircle',
      meta: {
        title: '圈子'
      }
    },{
      path: '/home/questionnaire',
      component: resolve => require(['@/views/home/questionnaire'], resolve),
      name: 'homequestionnaire',
      meta: {
        title: '问卷'
      }
    }, {
      path: '/home/message',
      component: resolve => require(['@/views/message/index'], resolve),
      name: 'message',
      meta: {
        title: '消息'
      }
    }, {
      path: '/home/my',
      component: resolve => require(['@/views/my/index'], resolve),
      name: 'homemy',
      meta: {
        title: '我的'
      }
    }]
  },{
    path:'/login',
    component: resolve => require(['@/views/login/login'], resolve),
    name: 'login',
    meta: {
      title: '登录'
    }
  },
  {
    path: '/circleDetails',
    component: resolve => require(['@/views/home/circleDetails'], resolve),
    name: 'circleDetails',
    meta: {
      title: '圈子详情'
    }
  },
  {
    path: '/activity/details',
    component: resolve => require(['@/views/activity/details'], resolve),
    name: 'activityDetails',
    meta: {
      title: '活动详情'
    }
  },
  {
    path: '/release',
    // component: resolve => require(['@/views/release/index'], resolve),
    component: ReleaseComment,
    name: 'release',
    meta: {
      title: '参与讨论'
    }
  },
  {
    path: '/my/profile',
    component: resolve => require(['@/views/my/profile/profile'], resolve),
    name: 'profile',
    meta: {
      title: '个人资料'
    }
  },
  {
    path: '/my/profile/changemobile',
    component: resolve => require(['@/views/my/profile/changeMobile'], resolve),
    name: 'changemobile',
    meta: {
      title: '更换手机号'
    }
  },
  {
    path: '/my/income/list',
    component: resolve => require(['@/views/my/income/list'], resolve),
    name: 'incomelist',
    meta: {
      title: '收益明细'
    }
  },
  {
    path: '/my/help',
    component: resolve => require(['@/views/my/help/help'], resolve),
    name: 'help',
    meta: {
      title: '帮助中心'
    }
  },
  {
    path: '/my/privacy',
    component: resolve => require(['@/views/my/components/privacy'], resolve),
    name: 'privacy',
    meta: {
      title: '用户协议'
    }
  },
  {
    path: '/my/setting',
    component: resolve => require(['@/views/my/setting'], resolve),
    name: 'setting',
    meta: {
      title: '设置'
    }
  },
  {
    path: '/home/test',
    component: resolve => require(['@/views/home/circleWaterfall'], resolve),
    name: 'test',
    meta: {
      title: '测试瀑布流'
    }
  },
  {
    path: '/callback/survey',
    component: resolve => require(['@/views/callback/survey'], resolve),
    name: 'survey',
    meta: {
      title: '结果'
    }
  },
  {
    path: '/login/survey',
    component: resolve => require(['@/views/login/survey'], resolve),
    name: 'login-survey',
    meta: {
      title: '登录'
    }
  },
  {
    path: '/pc',
    component: resolve => require(['@/views/pc'], resolve),
    name: 'pc',
    meta: {
      title: '首页'
    }
  },
  {
    path: '/clear',
    component: resolve => require(['@/views/clear/index'], resolve),
    name: 'clear',
    meta: {
      title: '清除token'
    }
  },
  {
    path: '/activity/releseForm',
    // component: resolve => require(['@/views/activity/releseForm'], resolve),
    component: ReleaseForm,
    name: 'activityForm',
    meta: {
      keepAlive: true,
      title: '参与问卷'
    }
  },
  {
    path: '/survey/guide',
    component: resolve => require(['@/views/survey/guide'], resolve),
    name: 'surveyGuide',
    meta: {
      title: '参与问卷'
    }
  },
];
// 路由管理者
let router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return {
      x: 0,
      y: 0
    };
  }
});

export default router;
