import { post } from '@/until/http'
import { Toast } from "vant";

import * as qiniu from 'qiniu-js'

// 获取七牛云token
export const getUploadToken = (data) => {
  return post('/common/getUpToken', data, {
    isToken: false
  })
}

//调取七牛云上传
export async function asyncUploadFileByQiNiu(file) {
  return new Promise(async (resolve, reject) => {
    file = file.file ? file.file : file
    let fileType = 'other'
    let fileName = file.name
    try {
      if (file.type.indexOf('image') >= 0) {
        fileType = "image"
      } else if (file.type.indexOf('video') >= 0) {
        fileType = "video"
      }
    } catch (e) {
      console.error(e.message)
    }

    const { code, data } = await getUploadToken({
      fileType: fileType,
      fileName: fileName,
    })

    // 没有获取到token
    if (code != 200) {
      return
    }

    var putExtra = {
      fname: '', //原文件名
      params: {}, //用来放置自定义变量
      mimeType: null //限制上传文件类型
    };
    var config = {
      concurrentRequestLimit: 10, //分片上传的并发请求量
      useCdnDomain: true,
      chunkSize: 10,
    };

    let observable = null
    //如果是图片 根据情况进行图片压缩
    if (file.type.indexOf('image') > -1) {
      const options = {
        quality: 0.9, //图片压缩质量
        noCompressIfLarger: true, // 如果发现压缩后图片大小比原来还大，则返回源图片
        // maxWidth: 1080, //最大宽度
        // maxHeight: 1080 //最大高度
      }
      const resData = await qiniu.compressImage(file, options)
      observable = qiniu.upload(resData.dist, null, data.upToken, putExtra, config)
    } else {
      observable = qiniu.upload(file, null, data.upToken, putExtra, config)
    }
    observable.subscribe({
      next(res) {},
      error(error) {
        reject(error)
      },
      complete(res) {
        resolve(data.domain + res.key)
      }
    })
  })
}