import {
  upload
} from '@/until/uploadFile'

//  上传

class uploadFile {
  constructor() {}

  url = {
    upload: '/common/upload',
    uploadBatch: '/common/uploadBatch'
  }

  // 上传
  update(para, callback, error) {
    upload(this.url.upload, para).then(res => {
      callback(res);
    }).catch((err)=>{
      error(err)
    })
  }

  // 批量上传
  uploadBatch(param, callback, error){
    upload(this.url.uploadBatch, param).then(res => {
      callback(res)
    }).catch((err) => {
      error(err)
    })
  }
}

export default new uploadFile();
