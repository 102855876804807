import wx from 'weixin-js-sdk';
import user from "@/api/user.js";

export function configWechat() {
	let webLink = {
		"url":window.location.href.split('#')[0]
	};
	user.getConfig(webLink,res => {
		wx.config({
			debug: false,
			appId: res.data.appId,
			timestamp: res.data.timestamp,
			nonceStr: res.data.noncestr,
			signature: res.data.signature,
			jsApiList: [
				'wx-open-subscribe',
				'onMenuShareTimeline',
				'onMenuShareAppMessage',
				'onMenuShareQQ',
				'onMenuShareWeibo',
				'onMenuShareQZone',
				'chooseWXPay',
				'updateAppMessageShareData',
				'updateTimelineShareData',
				'previewImage'
			],
			openTagList: ['wx-open-launch-weapp','wx-open-launch-app', 'wx-open-subscribe']
		});
	})
}

export default wx;
