<template>
  <div class="home">
    <!-- <transition name="fade" mode="in-out"> -->
    <router-view v-if="refresh"></router-view>
    <!-- </transition> -->
    <van-tabbar v-model="active" @change="goRouter">
      <van-tabbar-item v-for="(item,index) in list" :key="index" :dot="item.showDot">
        <span class="title">{{ item.title }}</span>
        <template #icon="props">
          <img class="iconImg" :src="!props.active ? item.active : item.inactive"/>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
  import { configWechat } from "@/until/wechatConfig"
  import untils from "@/until/until";

  export default {
    name: "home",
    data() {
      return {
        active: 0,
        routeChange: false,
        refresh: false,
        list: [{
          url: '/home/index',
          active: require('@/assets/img/home/shou_ye@2x.png'),
          inactive: require('@/assets/img/home/shou_ye1@2x.png'),
          title: '首页'
        }, {
          url: '/home/circle',
          active: require('@/assets/img/home/quan_zi@2x.png'),
          inactive: require('@/assets/img/home/quan_zi1@2x.png'),
          title: '圈子'
        }, {
          url: '/home/questionnaire',
          active: require('@/assets/img/home/wen_juan@2x.png'),
          inactive: require('@/assets/img/home/wen_juan1@2x.png'),
          title: '问卷'
        },
          {
          url: '/home/message',
          active: require('@/assets/img/home/message_normal.png'),
          inactive: require('@/assets/img/home/message_select.png'),
          title: '消息'
        },
          {
          url: '/home/my',
          active: require('@/assets/img/home/wo_de@2x.png'),
          inactive: require('@/assets/img/home/wo_de@2x(1).png'),
          title: '我的'
        }]
      };
    },
    created() {
      if (!this.refresh){
        if (untils.isWechat()){
          configWechat()
        }

        this.getUnReadMsg()
      }

      // 回显图标
      const path = this.$route.path
      this.active = this.list.findIndex(item => item.url === path)
    },
    watch: {
      $route(to) {
        this.active = this.list.findIndex(item => item.url === to.path)
        if (this.active === 3) {
          this.clearUnRead()
        }
      }
    },
    methods: {
      goRouter(e) {
        this.$router.push(this.list[e].url)
      },
      getUnReadMsg() {
        this.$api.message.getMessageList({pageNum: 1, pageSize: 1}, res => {
          this.list[3].showDot = res && res.unread && res.unread > 0
          this.refresh = true
        })
      },
      clearUnRead() {
        this.list[3].showDot = false
      }
    }
  };
</script>

<style lang="less" scoped>
  .fade-leave-to {
    display: none;
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-leave {
    opacity: 1;
  }

  .fade-enter-active {
    transition: opacity .5s;
  }

  .home {
    padding-bottom: 80px;
  }

  .title {
    color: #3E639A;
    font-size: 20px;
    line-height: 28px;
    color: #D8D8D8;
  }

  .van-tabbar-item--active .title {
    color: #3E639A;
  }

  .iconImg {
    width: 36px;
    height: 36px;
  }
</style>
