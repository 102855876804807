import axios from 'axios';

// 实例化
const instance = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // 公共地址
	timeout: 30000 // 请求超时时间
});

const uploadIns = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // 公共地址
	timeout: 30000000 // 请求超时时间
})

// 响应拦截器
instance.interceptors.response.use(
	response => {
		return Promise.resolve(response);
	},
	error => {
		return Promise.reject(error.response);
	}

);

 
 
 /**
  * 图片上传方法
  * @param {String} url [请求的url地址]    
  * @param {Object} params [请求时携带的参数] 
  */
 export function upload(url, params = {},header={ "Content-Type": "multipart/form-data" }) {
 	return new Promise((resolve, reject) => {
		uploadIns.post(url, params)
 			.then(res => {
 				resolve(res.data);
 			})
 			.catch(err => {
				 console.log(err)
 				reject(err ? err.data : "")
 			})
 	});
 }
 
