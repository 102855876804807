<template>
  <!-- 发布 -->
  <div class="release">
    <van-sticky>
      <van-nav-bar title="参与讨论" left-arrow @click-left="back" @click-right="release" right-text="发布" />
    </van-sticky>
    <div class="container" id="container" contenteditable="true" @click="recordLastEditRange"
      @keyup="recordLastEditRange"></div>
    <div class="footer van-hairline--top">
      <van-uploader :before-read="beforeRead" :after-read="afterRead" accept="image/gif,image/png,image/jpeg" multiple
        :max-count="9">
        <img class="releaseBtn" src="@/assets/img/release/tupian-2@2x.png" />
      </van-uploader>
      <van-uploader :after-read="afterRead" accept="video/*" :max-count="maxCount">
        <img class="releaseBtn video" src="@/assets/img/release/video@2x.png" />
      </van-uploader>
      <img class="releaseBtn" @click="showAddLinkDialog" src="@/assets/img/release/link.png" />
      <img class="releaseBtn" @click="showAddVideoDialog" src="@/assets/img/release/videolink.png" />
    </div>
    <van-dialog v-model="showAddLink" :before-close="onBeforeClose" title="添加超链接" :lock-scroll="true" show-cancel-button
      @cancel="cancelShowAddLink" @confirm="AddLink">
      <van-field v-model="addForm.name" label-width="40px" label="标题" placeholder="请输入标题" />
      <van-field v-model="addForm.link" label-width="40px" type="textarea" label="链接" placeholder="请输入链接" />
    </van-dialog>
    <van-dialog v-model="showAddVideo" :before-close="onBeforeClose" title="添加视频" :lock-scroll="true" show-cancel-button
      @cancel="cancelShowAddVideo" @confirm="AddVideo">
      <van-field v-model="link" label-width="60px" type="textarea" label="视频链接" placeholder="请输入视频链接" />
    </van-dialog>
  </div>
</template>

<script>
  import mixins from './mixins.js'
  import { asyncUploadFileByQiNiu } from '@/api/filecommon.js'
  export default {
    name: "release",
    mixins: [mixins],
    data() {
      return {
        showAddLink: false,
        showAddVideo: false,
        addForm: {
          name: '',
          link: ''
        },
        link: '',
        off: false,
        maxCount: 9,
        updateLength: 0 // 上传文件数量
      }
    },
    mounted() {
      let content = localStorage.getItem("release_" + this.$route.query.id + this.$route.query.commentId);
      if (content){
        let container = document.getElementById('container')
        container.innerHTML = content
      }
    },
    methods: {
      onBeforeClose(action, done) {
        return done(false)
      },
      //取消显示视频
      cancelShowAddVideo() {
        this.showAddVideo = false
      },
      AddVideo() {
        if (this.link === '') {
          this.$toast({
            message: '请输入链接',
            position: 'bottom'
          });
          return false
        }
        this.addVideo(this.link)
        this.showAddVideo = false
        this.link = ''
      },
      //添加视频
      addVideo(url) {
        let container = document.getElementById('container')
        let video = document.createElement('video');
        video.src = url
        video.style = "width:100%;margin-bottom:12px;"
        video.controls = true
        // container.innerHTML += '&nbsp;'
        // container.appendChild(video)
        this.LastEditRangeAppend(video)
        container.innerHTML += '<br />'
        localStorage.setItem("release_" + this.$route.query.id + this.$route.query.commentId, container.innerHTML)
      },
      //显示Video
      showAddVideoDialog() {
        this.showAddVideo = true
      },
      //取消显示
      cancelShowAddLink() {
        this.showAddLink = false
      },
      //取消显示
      AddLink() {
        if (this.addForm.name === '') {
          this.$toast({
            message: '请输入标题',
            position: 'bottom'
          });
          return false
        }
        if (this.addForm.link === '') {
          this.$toast({
            message: '请输入链接',
            position: 'bottom'
          });
          return false
        }
        // let container = document.getElementById('container')
        let aDocument = document.createElement('a');
        aDocument.href = this.addForm.link
        aDocument.innerText = this.addForm.name
        this.LastEditRangeAppend(aDocument)
        this.showAddLink = false
        this.addForm.link = ''
        this.addForm.name = ''
        localStorage.setItem("release_" + this.$route.query.id + this.$route.query.commentId, container.innerHTML)
      },
      //显示link
      showAddLinkDialog() {
        this.showAddLink = true
      },
      back() {
        this.$router.back(-1)
      },
      beforeRead(file) {
        if (Array.isArray(file) && file.length > this.maxCount) {
          this.$toast({
            message: '一次最多上传' + this.maxCount + '张图片',
            position: 'bottom'
          });
          return false
        }
        return true
      },
      //上传
      // afterRead(file) {
      //   this.$toast.loading({
      //     message: '上传中...',
      //     forbidClick: true,
      //     loadingType: 'spinner',
      //     duration: 0
      //   });
      //   if(Array.isArray(file)){
      //     const formData = new FormData();
      //     file.forEach((item, index) => {
      //       // 添加文件信息
      //       formData.append("file", item.file);
      //     })

      //     this.$api.uploadFile.uploadBatch(formData, (res) => {
      //       if (res.code === 200) {
      //         let container = document.getElementById('container')
      //         let imgContent = document.createElement('div');
      //         res.data.forEach(item => {
      //           let img = document.createElement('img');
      //           img.src = item.url
      //           img.style = "width:100%;margin-bottom:12px;"
      //           imgContent.appendChild(img)
      //         })
      //         this.LastEditRangeAppend(imgContent)
      //         container.innerHTML += '&nbsp;<div></div>'
      //         this.$toast.clear()
      //       } else {
      //         this.$toast({
      //           message: res.msg,
      //           position: 'bottom'
      //         });
      //       }
      //     }, (err) => {
      //       this.$toast({
      //         message: '上传异常，请重试',
      //         position: 'bottom'
      //       });
      //     })
      //   }else{
      //     const formData = new FormData();
      //     // 添加文件信息
      //     formData.append("file", file.file);
      //     this.$api.uploadFile.update(formData, (res) => {
      //       if (res.code === 200) {
      //         let container = document.getElementById('container')
      //         if (file.file.type.indexOf('image') >= 0) {
      //           let img = document.createElement('img');
      //           img.src = res.url
      //           img.style = "width:100%;margin-bottom:12px;"
      //           // container.appendChild(img)
      //           // container.execCommand('InsertImage', false,  res.url);
      //           this.LastEditRangeAppend(img,res.url)
      //           container.innerHTML += '&nbsp;<div></div>'
      //         } else {
      //           this.addVideo(res.url)
      //         }
      //         this.$toast.clear()
      //       } else {
      //         this.$toast({
      //           message: res.msg,
      //           position: 'bottom'
      //         });
      //       }
      //     }, (ree) => {
      //       console.log(ree)
      //       this.$toast({
      //         message: '上传异常，请重试',
      //         position: 'bottom'
      //       });
      //     })
      //   }
      // },
      afterRead(file) {
        this.$toast.loading({
          message: '上传中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });
        if (Array.isArray(file)) {
          this.updateLength = file.length
          file.forEach((item) => {
            this.uploadFile(item.file)
          })
        } else {
          this.updateLength = 1
          this.uploadFile(file.file)
        }
      },
      async uploadFile(file) {
        try {
          const fileUrl = await asyncUploadFileByQiNiu(file)
          // 成功调用一次则减一次
          this.updateLength--
          // 上传后处理
          if (file.type.indexOf('image') >= 0) {
            let img = document.createElement('img');
            img.src = fileUrl
            img.style = "width:100%;margin-bottom:12px;"
            this.LastEditRangeAppend(img, fileUrl)
            container.innerHTML += '&nbsp;<div></div>'
          } else {
            this.addVideo(fileUrl)
          }
          //当上传数量为0 隐藏弹框
          if (this.updateLength === 0) {
            localStorage.setItem("release_" + this.$route.query.id + this.$route.query.commentId, container.innerHTML)
            this.$toast.clear()
          }
        } catch (error) {
          console.log(error)
          //错误了
          this.$toast.clear()
          this.$toast({
            message: '上传异常，请重试',
            position: 'bottom'
          });
        }
      },
      release() {
        const commentId = this.$route.query.commentId
        if (commentId) {
          document.title = '回复'
          this.reply()
        } else {
          this.releaseComments()
          document.title = '参与讨论'
        }
      },
      // 发布评论
      releaseComments() {
        let container = document.getElementById('container')
        if (container.innerHTML === "") {
          this.$toast({
            message: '请输入内容',
            position: 'bottom'
          });
          return false
        }
        if (this.off) {
          return false
        }
        this.off = true
        this.$toast.loading({
          message: '发布中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });
        this.$api.home.commentAdd({
          activityId: this.$route.query.id,
          content: container.innerHTML
        }, res => {
          if (res.code === 200) {
            localStorage.removeItem("release_" + this.$route.query.id + this.$route.query.commentId)
            this.$toast.clear()
            this.$router.back(-1)
          } else {
            this.off = false
          }
        })
      },
      // 回复
      reply() {
        let container = document.getElementById('container')
        if (container.innerHTML === "") {
          this.$toast({
            message: '请输入内容',
            position: 'bottom'
          });
          return false
        }
        const replyData = {
          commentId: this.$route.query.commentId,
          content: container.innerHTML
        }
        if (this.$route.query.parentId != '') {
          replyData.parentId = this.$route.query.parentId
        }
        if (this.$route.query.userId != '') {
          replyData.auserId = this.$route.query.userId
          replyData.anickName = this.$route.query.nickName
        }
        if (this.off) {
          return false
        }
        this.off = true
        this.$toast.loading({
          message: '发布中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });
        this.$api.home.reply(replyData, res => {
          if (res.code === 200) {
            localStorage.removeItem("release_" + this.$route.query.id + this.$route.query.commentId)
            this.$toast.clear()
            this.$router.back(-1)
          } else {
            this.off = false
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  /deep/ .van-nav-bar .van-icon {
    color: #CACACA;
  }

  /deep/ .van-nav-bar__right {
    font-size: 36px;
    font-weight: bold;
  }

  /deep/ .van-nav-bar__text {
    color: #3E639A;
  }

  .release {
    width: 100%;
    height: 100vh;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;

    .container {
      -webkit-user-select: text;
      width: 100%;
      flex: 1;
      outline: none;
      box-sizing: border-box;
      padding: 40px 30px 40px;
      font-size: 32px;
      color: #444444;
      line-height: 52px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      & /deep/ video {
        width: 100%;
        height: 400px;
        background-color: #000000;
      }
    }


    .footer {
      // position: fixed;
      // left: 0;
      // bottom: 0;
      // z-index: 9;
      width: 100%;
      height: 80px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-between;
      padding: 0 30px;

      .releaseBtn {
        width: 40px;
        height: 36px;

        &.video {
          width: 46px;
          height: 32px;
        }

        &:nth-child(3) {
          width: 42px;
          height: 40px;
        }

        &:nth-child(4) {
          width: 54px;
          height: 38px;
        }
      }

    }
  }

  video {
    display: inline;
  }
</style>
