// 引入 VUE
import Vue from 'vue'
import {
	getDateDiff
} from '@/until/date.js'

/*
 * 点击次数限制 
 * binding 限制时间
 * 只能限制button input
 */
export const preventReClick = Vue.directive('preventReClick', {
	inserted: function(el, binding) {
		el.addEventListener('click', () => {
			if (!el.disabled) {
				el.disabled = true
				setTimeout(() => {
					el.disabled = false
				}, binding.value || 3000)
			}
		})
	}
});

/*
 * 随机背景色
 * colorList 颜色数组
 */
export const RandomBgColor = Vue.directive('RandomBgColor', {
	inserted: function(el, binding) {
		//默认背景颜色列表
		const backgroundColorList = ['#FFAFCC', '#FFC8DD', '#CDB4DB', '#BDE0FE', '#A2D2FF', '#974F3A', '#9D949E',
			'#C5A897', '#CEC0B4'
		];
		//先取随机颜色
		let color = backgroundColorList[Math.floor(Math.random() * backgroundColorList.length)];
		//获取用户颜色 如果有则取用户颜色随机值
		const colorList = binding.value;
		let userColor;
		if (colorList) {
			userColor = binding.value[Math.floor(Math.random() * binding.value.length)];
		}
		el.style.backgroundColor = userColor ? userColor : color;
	}
});

/*
 * 同步处理时间字段
 */
export const dateDiff = Vue.directive('dateDiff', {
	inserted: function(el, binding) {
		let elValue = el.innerText;
		let hourTime = el.innerText.substring(11, 16)
		if (binding.value) {
			el.innerText = getDateDiff(elValue) + ' ' + hourTime;
		} else {
			el.innerText = getDateDiff(elValue);
		}
	}
});
/*
 * 异步处理时间字段
 */
export const asynDateDiff = Vue.directive('asynDateDiff', {
	inserted: function(el, binding) {
		var config = {
			characterData: true,
			subtree: true
		};
		var observer = new MutationObserver(function(mutationsList, observer) {
			if (mutationsList[0].type == 'characterData') {
				let elValue = el.innerText;
				let hourTime = el.innerText.substring(11, 16)
				if (binding.value) {
					el.innerText = getDateDiff(elValue) + ' ' + hourTime;
				} else {
					el.innerText = getDateDiff(elValue);
				}
			}
		});
		observer.observe(el, config);
	}
});
