import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
//引入rem
import 'amfe-flexible'
//引入组件
import 'vant/lib/index.css';
import Vant from 'vant';
// 引入公共的css
import '@/assets/css/reset.css'
//引入
import store from './store'
//引入时间插件
import dayjs from 'dayjs'
//全局注入api
import configApi from '@/api'
//全局注入公共方法
import untils from '@/until/until'

import VmFormRender from './vform/VmFormRender.umd.min.js'   //引入VFormRender组件
import './vform/VmFormRender.css'  //引入VFormRender样式
Vue.use(VmFormRender)  //全局注册VFormRender等组件
// 忽略
Vue.config.ignoredElements = ['wx-open-subscribe']
// import Vconsole from 'vconsole';
// new Vconsole()

import {
  preventReClick,
  RandomBgColor,
  dateDiff,
  asynDateDiff
} from '@/directive/directive.js'
Vue.use(Vant)
Vue.config.productionTip = false;

//使用自定义指令
Vue.use(preventReClick).use(dateDiff).use(asynDateDiff).use(RandomBgColor)
Vue.use(configApi)
Vue.prototype.dayjs = dayjs;
Vue.prototype.$untils = untils;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }

  next()

})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
