export const getDateDiff = dateStr => {
  let minute = 1000 * 60;
  let hour = minute * 60;
  let day = hour * 24;
  let now = new Date().getTime();
  let dateTimeStamp = getDateTimeStamp(dateStr);
  let diffValue = now - dateTimeStamp;
  if (diffValue < 0) {
    return;
  }

	// let halfamonth = day * 15;
	// let month = day * 30;
  // let monthC = diffValue / month;
  let weekC = diffValue / (7 * day);
  let dayC = diffValue / day;
  let hourC = diffValue / hour;
  let minC = diffValue / minute;
  let result = "";
  // if(monthC>=1){
  // 	result="" + parseInt(monthC) + "月前";
  // }
  // else if(weekC>=1){
  // 	result="" + parseInt(weekC) + "周前";
  // }
  if (weekC >= 1) {
    let date = new Date()
    date.setTime(dateTimeStamp);
    result = date.toLocaleDateString()
  } else if (dayC >= 1) {
    result = "" + parseInt(dayC) + "天前";
  } else if (hourC >= 1) {
    result = "" + parseInt(hourC) + "小时前";
  } else if (minC >= 1) {
    result = "" + parseInt(minC) + "分钟前";
  } else
    result = "刚刚";

  return result;
}

const getDateTimeStamp = dateStr => {
  return Date.parse(dateStr.replace(/-/gi, "/"));
}