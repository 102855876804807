import user from "@/api/user"
import untils from "@/until/until"
import router from '@/router/router'
import {
  Toast
} from 'vant';

const noBindLink = ['/login/survey']
// console.log(router)
const wxLogin = (obj, appid) => {
  return new Promise((resolve, reject) => {
    // 如果不是微信打开、提示错误
    if (untils.isWechat()) {
      let code = untils.getUrlParam("code");
      if (code) {
        let codeObj = {
          "code": code
        }
        user.wxLogin(codeObj, res => {
          if (res.code == 200) {
            localStorage.setItem('userInfo', JSON.stringify(res.data));
            localStorage.setItem('sessionKey', res.data.sessionKey);
            localStorage.setItem('openid', res.data.openId);
            if (res.data.phone === null || res.data.phone === '') {
              // 非 login/survey 界面，跳转到登录页
              // if (!noBindLink.includes(document.location.pathname)) {
              //   router.replace('/login?loginType=2&wxCode=' + code)
              // } else {
                resolve(res)
              // }
            } else {
              resolve(res)
            }
          } else if (res.code === 40163) {
            // code 失效时，重新授权
            let url = encodeURIComponent(obj);
            window.location = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
              appid +
              "&redirect_uri=" +
              url +
              "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
          } else {
            Toast({
              message: res.msg,
              position: 'bottom'
            });
            reject(res)
          }
        });
      } else {
        let url = encodeURIComponent(obj);
        window.location = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          appid +
          "&redirect_uri=" +
          url +
          "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
      }
    }
  })
}

/**
 * 一次性订阅消息
 *  授权成功后会返回 openid 和 template_id
 */
const weSubscribeMessage = (obj, appid, templateId) => {
  return new Promise(() => {
    // 如果不是微信打开、提示错误
    if (untils.isWechat()) {
      let url = encodeURIComponent(obj);
      window.location = "https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm" +
        "&appid=" + appid + "&scene=1000" +
        "&template_id=" + templateId + "&redirect_url=" + url + "&reserved=test#wechat_redirect";
    }
  })
}

//判断是否授权
const getWechatAppId = () => {
  return new Promise((resolve, reject) => {
    user.getAppId({}, res => {
      if (res.code == 200) {
        resolve(res)
        // wxLogin(window.location.href, res.data.appId);
      } else {
        Toast({
          message: res.msg,
          duration: 1500,
          forbidClick: true
        });
        reject(res)
      }
    })
  })
}

export default {
  getWechatAppId,
  wxLogin,
  weSubcribeMessage: weSubscribeMessage
}
