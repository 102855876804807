// 光标位置相关处理
export default {
  data() {
    return {
      lastEditRange: null //记录光标位置
    }
  },
  methods: {
    //记录最后光标对象
    recordLastEditRange() {
      let container = document.getElementById('container')
      localStorage.setItem("release_" + this.$route.query.id + this.$route.query.commentId, container.innerHTML)
      // 获取选定对象
      let selection = getSelection()
      // 设置最后光标对象
      this.lastEditRange = selection.getRangeAt(0)
    },
    //在最后节点添加文本
    LastEditRangeAppend(domNodeHtml) {
      // 获取编辑框对象
      let container = document.getElementById('container')
      // 编辑框设置焦点
      container.focus()
      // 获取选定对象
      let selection = getSelection()
      // 判断是否有最后光标对象存在
      if (this.lastEditRange) {
        // 存在最后光标对象，选定对象清除所有光标并添加最后光标还原之前的状态
        selection.removeAllRanges()
        //添加光标位置
        selection.addRange(this.lastEditRange)
        this.lastEditRange.insertNode(domNodeHtml)
        let range = window.getSelection(); //创建range
        range.collapseToEnd(); //光标移至
        container.blur()
        // 无论如何都要记录最后光标对象
        this.lastEditRange = selection.getRangeAt(0)
      } else {
        container.appendChild(domNodeHtml)
      }
     
    }
  }
}
