<template>
  <!-- 发布表单活动 -->
  <div class="releseForm">
    <van-empty v-if="error" description="暂无数据，点击重试"
               @click.native="getActivity($route.query.id)"/>
    <vm-form-render v-else class="vmFormRefContent" :form-data="formData"  ref="vmFormRef" @formChange="handleFormChange">
    </vm-form-render>
    <van-button class="partakeBtn" @click="submitForm" :disabled="isSubmit">发布</van-button>
  </div>
</template>
<script>
  import until from '@/until/until.js'
  export default {
    data() {
      return {
        formData: {},
        error: false,
        isSubmit: false
      }
    },
    mounted() {
      this.getActivity(this.$route.query.id)
    },
    methods: {
      getCacheKey(){
        return "form_" + this.$route.query.id;
      },
      handleFormChange(fieldName, newValue, oldValue, formModel) {
        // console.log('---formChange start---')
        // console.log('fieldName', fieldName)
        // console.log('newValue', newValue)
        // console.log('oldValue', oldValue)
        // console.log('formModel', formModel)
        // console.log('---formChange end---')

        localStorage.setItem(this.getCacheKey(), JSON.stringify(formModel))
      },
      //提交表单数据
      submitForm() {
        if (!this.$refs.vmFormRef){
          this.$toast.fail('数据异常，请刷新后再试')
          return
        }

        this.$toast.loading({
          message: '发布中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });
        this.isSubmit = true
        this.$refs.vmFormRef.getFormData().then(formData => {
          let formContent = JSON.stringify(formData);
          if (formContent === '{}' && until.isJSON(formContent)){
            this.isSubmit = false
            this.$toast.fail('请回答后再提交')
            return
          }
          this.$api.home.commentAdd({
            activityId: this.$route.query.id,
            content: formContent
          }, res => {
            if (res.code === 200) {
              localStorage.removeItem(this.getCacheKey())
              this.$toast.success('提交成功')
              this.$router.back(-1)
            } else {
              this.isSubmit = false
              this.$toast.fail(res.msg)
            }
          })
        }).catch(error => {
          this.isSubmit = false
          this.$toast.fail(error)
        })
      },
      //获取活动详情
      getActivity(id) {
        let formContent = localStorage.getItem(this.getCacheKey());
        if (formContent){
          this.formData = JSON.parse(formContent)
        }

        this.$api.home.activity({
          id: id
        }, res => {
          if (res.code === 200) {
            this.error = false

            this.$nextTick(() => {
              setTimeout(() => {
                this.$refs.vmFormRef.setFormJson(JSON.parse(res.data.formContent))
                this.$refs.vmFormRef.setReadMode(false)
              })
            })
          }else {
            this.error = true
          }
        })
      },
    }
  }
</script>
<style lang="less" scoped>
  @import '~@/vform/vmFormRefContent.less';
  .releseForm {
    padding: 15px;

    .partakeBtn {
      width: 100%;
      height: 80px;
      background: #3E639A;
      border-radius: 4px;
      font-size: 32px;
      font-weight: bold;
      color: #FFFFFF;
      margin: 30px auto 0;
    }
  }
</style>
